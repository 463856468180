<!-- 카테고리 -->
<template>
  <div>
    <v-app-bar
      color="default"
      internal-activator
      class="fixed-bar-exhibition-event-header"
      bottom
      flat
      height="44"
    >
      <div class="d-flex justify-space-between fill-width align-center">
        <v-icon small @click="onClickPrevIcon">
          $vuetify.icons.prevIcon
        </v-icon>
        <span
          class="one-line-string rixgo-extrabold align-center gray900--text font-g16-24"
          >[EVENT] 9월의 친구를 찾습니다!</span
        >
        <div class="pb-1" @click="onClickShareIcon">
          <v-icon>$vuetify.icons.shareIcon</v-icon>
        </div>
      </div>
    </v-app-bar>
    <div class="fill-width">
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/promotionSignUpSeptember1.webp')
        "
        height="auto"
      />
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/promotionSignUpSeptember2.webp')
        "
        height="auto"
      />
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/promotionSignUpSeptember3.webp')
        "
        height="auto"
      />
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/promotionSignUpSeptember4.webp')
        "
        height="auto"
      />
      <div class="fill-width fixed-bar-button-area">
        <div class="d-flex font-g12-14 white--text">
          <div
            class="d-flex justify-center align-center width-50 rixgo-bold"
            style="background: #535353; height: 60px"
            @click="onClickKakaoChannel()"
          >
            톡채널 추가하기
          </div>
          <div
            class="d-flex justify-center align-center width-50 rixgo-bold"
            style="background: #2e2e2e; height: 60px"
            @click="onClickSubmit()"
          >
            톡채널 추가 이벤트 응모하기
          </div>
        </div>
      </div>
    </div>
    <Sharing
      :content="shareProps.description"
      :shareItem="shareItem"
      :show="share"
      :url="shareProps.copyUrl"
      :store="shareProps.title"
      sheetTitle=""
      @onClose="share = false"
    />
    <DialogAlertView
      :dialogAlert="dialogAlert"
      :alertText="alertTextHeader"
      :alertDescription="alertTextDescription"
      @alertConfirmClick="alertConfirmClick"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Sharing from '../../common/dialogs/Sharing.vue'
import DialogAlertView from '../login/DialogAlert.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { openSubWebView } from '@/plugins/util/appInterface'
export default Vue.extend({
  name: 'PromotionSignUpSeptemberView',
  components: {
    Sharing,
    DialogAlertView,
  },
  props: {
    scrollLocation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      alertTextHeader: '',
      alertTextDescription: '',
      dialogAlert: false,
      share: false,
      shareItem: {
        title: '[EVENT] 9월의 친구를 찾습니다!',
        description: '',
        imageUrl:
          'https://admin-img.cellook.kr/event/september-friend-share.png',
        url: window.location.origin + '/promotionSignUpSeptember',
      },
      shareProps: {
        title: '[EVENT] 9월의 친구를 찾습니다!',
        description: '',
        copyUrl: window.location.origin + '/promotionSignUpSeptember',
      },
    }
  },
  async created() {
    this.$getAppHtml.addEventListener('scroll', this.handleScroll)
    //shopId에 맞게 JSON 데이터 바인딩
  },
  destroyed() {
    this.$getAppHtml.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.setScrollLocation()
  },
  computed: {
    ...mapState('DeviceStore', ['deviceInfo']),
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('ExhibitionStore', ['fetchEventExhibitionScrollLocation']),
    onClickImage(e) {
      console.log(e.offsetX, e.offsetY)
    },
    setScrollLocation() {
      setTimeout(() => {
        window.scrollTo({
          top: this.scrollLocation,
          behavior: 'auto',
        })
      }, 1000)
    },
    onClickPrevIcon() {
      this.$router.push('/')
    },
    onClickShareIcon() {
      this.share = true
    },
    onClickKakaoChannel() {
      this.setExhibitionEventScrollLocation()
      const payload = {
        url: 'https://pf.kakao.com/_IBLbK/friend',
        title: '[EVENT] 9월의 친구를 찾습니다!',
        isGoneBottomView: true,
      }
      if (this.$device.isIOS && this.deviceInfo !== null) {
        openSubWebView(payload)
      } else if (this.$device.isAndroid && this.deviceInfo !== null) {
        openSubWebView(payload)
      } else if (
        (this.deviceInfo === null && this.$isMobile()) ||
        this.$device.browser_name === 'Safari' || // PC
        this.$device.browser_name.startsWith('Chrome Mobile')
      ) {
        history.pushState(null, 'null', location.href)
        window.location.replace(payload.url)
      } else {
        window.open(payload.url, '_blank')
      }
      if (this.$device.isAndroid && this.deviceInfo !== null) {
        window.CellookBridge.closeModal(true)
      }
    },
    alertConfirmClick() {
      this.dialogAlert = false
    },
    onClickSubmit() {
      this.setExhibitionEventScrollLocation()
      const payload = {
        url: 'https://docs.google.com/forms/d/e/1FAIpQLSddeJQudnMwiYEgRqxO5pfKlo4GpYiiu4OgqQtxvTjv_IMdFg/viewform',
        title: '[EVENT] 9월의 친구를 찾습니다!',
        isGoneBottomView: true,
      }
      if (this.$device.isIOS && this.deviceInfo !== null) {
        openSubWebView(payload)
      } else if (this.$device.isAndroid && this.deviceInfo !== null) {
        openSubWebView(payload)
      } else if (
        (this.deviceInfo === null && this.$isMobile()) ||
        this.$device.browser_name === 'Safari' || // PC
        this.$device.browser_name.startsWith('Chrome Mobile')
      ) {
        history.pushState(null, 'null', location.href)
        window.location.replace(payload.url)
      } else {
        window.open(payload.url, '_blank')
      }
      if (this.$device.isAndroid && this.deviceInfo !== null) {
        window.CellookBridge.closeModal(true)
      }
    },
    handleScroll() {
      this.fetchEventExhibitionScrollLocation(this.$getAppHtml.scrollTop)
    },
    setExhibitionEventScrollLocation() {
      localStorage.setItem(
        'exhibitionEventScrollLocation',
        JSON.stringify(this.$getAppHtml.scrollTop)
      )
    },
  },
})
</script>
<style scoped>
.fixed-bar-exhibition-event-header {
  position: sticky;
  top: 0em;
  z-index: 102;
}
.bar {
  border-bottom: 1px solid #eaebed;
}
.fixed-bar-button-area {
  position: sticky;
  bottom: 4.6em;
  z-index: 1;
}
</style>
